import { ArrowSquareOut, Binoculars, Buildings, CarSimple, Check, Gauge, Path, Question, ThumbsUp, TrafficCone, TrafficSign, Warning } from "phosphor-react";
import Button from "../components/Button";
import PageLayout from "../components/PageLayout";
import { Divider } from "@mui/material";
import { PersonSimpleBike, RoadHorizon, SteeringWheel } from "@phosphor-icons/react";
import clsx from "clsx";

export const Training = () => {
    return (
        <PageLayout title="Training" header={false}>
            <div className="flex flex-col items-center">
                <div className="flex flex-wrap justify-center gap-8">
                    <InfoCard isFleetAcademy={true} />
                    <InfoCard isFleetAcademy={false} />
                </div>
                <ModuleGrid />
            </div>
        </PageLayout>
    );
};

const InfoCard = ({ isFleetAcademy }: { isFleetAcademy: boolean }) => {
    const bgImageString = isFleetAcademy
        ? "bg-fleet-academy-banner"
        : "bg-e-training-banner";

    return (
        <div className="relative w-[450px] rounded-sm border-2 border-S2D-neutral-80 bg-gray-100 md:w-[550px]">
            <div
                className={clsx(
                    "h-[350px] w-full rounded-t-sm bg-cover bg-center",
                    bgImageString
                )}
            >
                <div className="absolute inset-0 h-[350px] rounded-t-sm bg-S2D-dark-green-20 opacity-70"></div>
                <div className="relative z-10 flex h-full items-center justify-center">
                    <h1 className="absolute top-10 text-4xl text-white">
                        {isFleetAcademy ? "Fleet Academy" : "Driver E-Training"}
                    </h1>
                    <div className="absolute bottom-5 flex w-full gap-6 px-4">
                        <Button
                            className="w-full justify-center gap-2"
                            onClick={() =>
                                isFleetAcademy
                                    ? (window.location.href =
                                          "https://safe2drive.fleetsafetyacademy.co.uk")
                                    : (window.location.href =
                                          "mailto:sales@safe2drive.co.uk")
                            }
                        >
                            More information
                            <Question size={24} />
                        </Button>
                        <Button
                            className="w-full justify-center gap-2"
                            onClick={() =>
                                isFleetAcademy
                                    ? (window.location.href =
                                          "https://safe2drive.fleetsafetyacademy.co.uk/login")
                                    : (window.location.href =
                                          "https://www.driverassess.com/login")
                            }
                        >
                            Click here to login
                            <ArrowSquareOut size={24} />
                        </Button>
                    </div>
                </div>
            </div>
            {/* Content */}
            <div>
                <div className="p-4 text-lg">
                    <p className="pb-4 text-center">
                        {isFleetAcademy
                            ? "A management system designed for Managers and Drivers in any organisation to use. Includes downloadable and editable policies, procedures, checklists, templates & videos"
                            : "A combination of high definition video footage and still images, and driver risk assessments. Various modules are available covering all commercial vehicle types."}
                    </p>
                    <Divider />
                    {isFleetAcademy ? (
                        <FleetAcademyCards />
                    ) : (
                        <DriverTrainingCards />
                    )}
                </div>
            </div>
        </div>
    );
};

const FleetAcademyCards = () => {
    return (
        <div>
            <CheckMarkCard text={"Instant access to all the resources you need"}/>
            <CheckMarkCard text={"A step-by-step roadmap for developing your driver safety management system"}/>
            <CheckMarkCard text={"Download, brand and adapt content for your company use"}/>
            <CheckMarkCard text={"Send out video based driver training"}/>
            <CheckMarkCard text={"Keep drivers engaged with regular content updates"}/>
        </div>
    );
};

const DriverTrainingCards = () => {
    return (
        <div>
            <CheckMarkCard text={"HD video footage filmed using in-vehicle cameras, roadside cameras and drones"}/>
            <CheckMarkCard text={"4 stage risk assessment covering: Attitude, Knowledge, Concentration/Observation and Hazard Perception"}/>
            <CheckMarkCard text={"Drivers are immediately given an overall risk rating of High, Medium or Low risk"}/>
            <CheckMarkCard text={"Assessments can be carried out on PCs, laptops, tablets and mobile phones"}/>
            <CheckMarkCard text={"Driver training modules, can be accessed 24/7 with minimal disruption to drivers’ time"}/>
        </div>
    );
};

const CheckMarkCard = ({text}: {text: string}) => {
    return (
        <div className="my-4 p-4 bg-S2D-dark-green-30 flex items-center rounded-sm">
            <Check className="text-S2D-light-green-80" size={40}></Check>
            <p className="text-white pl-4">{text}</p>
        </div>

    );
};

const moduleGridData = [
    {icon: <Warning className="mb-4" size={50}/>, text: "Avoid hitting 3rd party in rear"},
    {icon: <SteeringWheel className="mb-4" size={50}/>, text: "Parking\n & manoeuvring"},
    {icon: <Binoculars className="mb-4" size={50}/>, text: "Concentration\n & observation"},
    {icon: <Gauge className="mb-4" size={50}/>, text: "Speed \nawareness"},
    {icon: <PersonSimpleBike className="mb-4" size={50}/>, text: "Cyclist awareness \nfor drivers	"},
    {icon: <TrafficSign className="mb-4" size={50}/>, text: "UK Road signs \n& markings"},
    {icon: <ThumbsUp className="mb-4" size={50}/>, text: "Driver \nattitude"},
    {icon: <CarSimple className="mb-4" size={50}/>, text: "Vehicle \nsafety checks"},
    {icon: <Path className="mb-4" size={50}/>, text: "Rural \ndriving"},
    {icon: <RoadHorizon className="mb-4" size={50}/>, text: "Motorway & dual \ncarriageways"},
    {icon: <Buildings className="mb-4" size={50}/>, text: "City \ndriving"},
    {icon: <TrafficCone className="mb-4" size={50}/>, text: "Hazard \nperception"},
];

const ModuleGrid = () => {
    return (
        <div className="text-center my-12 flex flex-col items-center">
            <h1 className="text-S2D-dark-green-30 text-4xl">Available Modules</h1>
            <p className="text-lg mt-4">Tailor your driver training to where it's needed the most</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-4 p-8">
                {moduleGridData.map((card, index) => (
                    <div key={`module-${index}`} className="border-2 border-S2D-neutral-80 bg-S2D-dark-green-70.3 flex flex-col items-center justify-center p-4 rounded-sm h-[200px] min-w-[200px] w-full">
                        {card.icon}
                        {card.text.split("\n").map((str, i) => <h1 key={`module-heading-${index}-${i}`} className="bold text-lg text-center">{str}</h1>)}
                    </div>
                ))}
            </div>
        </div>
    );
};